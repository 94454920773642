var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h4',{staticClass:"text-uppercase"},[_vm._v("Profile Details")]),_c('CRow',[_c('CCol',{staticClass:"pr-0",attrs:{"sm":"6"}},[_c('CCard',{staticClass:"px-4"},[_c('CRow',[_c('CCol',{staticClass:"avatar-container"},[_c('img',{staticClass:"mb-2 img",attrs:{"name":"cil-user","src":_vm.$auth.user().photo_url ? _vm.$auth.user().photo_url  : '/user.png'}}),_c('validation-observer',[_c('ValidationProvider',{ref:"photoValidator",attrs:{"name":"image","vid":"image","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('button',{staticClass:"btn btn-info",on:{"click":_vm.onPickFile}},[_vm._v(" Change ")]),_c('p',{staticClass:"pt-3 w-100 error-text"},[_vm._v("* Photo size 20KB to 200KB")]),_c('input',{ref:"fileInput",staticStyle:{"display":"none"},attrs:{"name":"image","type":"file","accept":"image/*"},on:{"change":_vm.onFilePicked}}),_c('span',{staticClass:"text-danger mt-0 pt-0 error-text"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('CCol',{staticClass:"mt-3"},[(_vm.$auth.check())?_c('ul',{staticClass:"customer-detail-list"},[_c('li',[_c('p',[_vm._v("Name:")]),_c('p',[_vm._v(_vm._s(_vm.$auth.user().first_name + " " + _vm.$auth.user().last_name))])]),_c('li',[_c('p',[_vm._v("Email:")]),_c('p',[_vm._v(_vm._s(_vm.$auth.user().email))])])]):_vm._e()])],1),_c('ul',{staticClass:"customer-detail-list"},[_c('p',{staticClass:"mt-3 mb-3 change-pass"},[_vm._v("Change Password")]),_c('hr'),_c('li',[(_vm.serverErrors.length)?_c('div',_vm._l((_vm.serverErrors),function(err){return _c('CAlert',{key:err,attrs:{"color":"danger","closeButton":""},model:{value:(_vm.showDismissibleAlert),callback:function ($$v) {_vm.showDismissibleAlert=$$v},expression:"showDismissibleAlert"}},[_vm._v(" "+_vm._s(err)+" ")])}),1):_vm._e(),_c('validation-observer',{ref:"formValidator"},[_c('form',{staticClass:"ui form",attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.changePassword.apply(null, arguments)}}},[_c('ValidationProvider',{attrs:{"name":"Current Password","vid":"current_password","rules":{ required: true, min: 6 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"field"},[_c('label',[_vm._v("Current Password")]),_c('CInput',{attrs:{"type":"password","name":"current_password","placeholder":"Current Password","required":"","addInputClasses":errors.length ? 'is-invalid' : ''},model:{value:(_vm.profile.current_password),callback:function ($$v) {_vm.$set(_vm.profile, "current_password", $$v)},expression:"profile.current_password"}}),_c('span',{staticClass:"text-danger mt-0 pt-0"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('ValidationProvider',{attrs:{"name":"New Password","vid":"password","rules":{ required: true, min: 6 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"field"},[_c('label',[_vm._v("New Password")]),_c('CInput',{attrs:{"type":"password","name":"password","placeholder":"New Password","required":"","addInputClasses":errors.length ? 'is-invalid' : ''},model:{value:(_vm.profile.password),callback:function ($$v) {_vm.$set(_vm.profile, "password", $$v)},expression:"profile.password"}}),_c('span',{staticClass:"text-danger mt-0 pt-0"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('ValidationProvider',{attrs:{"name":"Confirm Password","vid":"confirm_password","rules":{ required: true, min: 6 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"field"},[_c('label',[_vm._v("Confirm Password")]),_c('CInput',{attrs:{"type":"password","name":"password_confirmation","placeholder":"Confirm Password","required":"","addInputClasses":errors.length ? 'is-invalid' : ''},model:{value:(_vm.profile.password_confirmation),callback:function ($$v) {_vm.$set(_vm.profile, "password_confirmation", $$v)},expression:"profile.password_confirmation"}}),_c('span',{staticClass:"text-danger mt-0 pt-0"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit"}},[_vm._v(" Update Password ")])],1)])],1)])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }