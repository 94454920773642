<template>
  <div>
    <h4 class="text-uppercase">Profile Details</h4>

    <CRow>
      <CCol sm="6" class="pr-0">
        <CCard class="px-4">
          <CRow>
            <CCol class="avatar-container">
              <img
                class="mb-2 img"
                name="cil-user"
                :src="$auth.user().photo_url ? $auth.user().photo_url  : '/user.png'"
              />

              <validation-observer>
                <ValidationProvider
                  ref="photoValidator"
                  name="image"
                  v-slot="{ errors }"
                  vid="image"
                  :rules="{ required: true }"
                >
                  <button class="btn btn-info" @click="onPickFile">
                    Change
                  </button>
                  <p class="pt-3 w-100 error-text">* Photo size 20KB to 200KB</p>

                  <input
                    name="image"
                    type="file"
                    style="display: none"
                    ref="fileInput"
                    accept="image/*"
                    @change="onFilePicked"
                  />
                  <span class="text-danger mt-0 pt-0 error-text">{{ errors[0] }}</span>
                </ValidationProvider>
              </validation-observer>
            </CCol>

            <CCol class="mt-3">
              <ul class="customer-detail-list" v-if="$auth.check()">
                <li>
                  <p>Name:</p>
                  <p>{{ $auth.user().first_name + " " + $auth.user().last_name }}</p>
                </li>
                <li>
                  <p>Email:</p>
                  <p>{{ $auth.user().email }}</p>
                </li>
              </ul>
            </CCol>
          </CRow>

          <ul class="customer-detail-list">
            <p class="mt-3 mb-3 change-pass">Change Password</p>
            <hr />
            <li>
              <div v-if="serverErrors.length">
                <CAlert
                  v-model="showDismissibleAlert"
                  color="danger"
                  closeButton
                  v-for="err in serverErrors"
                  :key="err"
                >
                  {{ err }}
                </CAlert>
              </div>
              <validation-observer ref="formValidator">
                <form
                  class="ui form"
                  autocomplete="off"
                  @submit.prevent="changePassword"
                >
                  <ValidationProvider
                    name="Current Password"
                    v-slot="{ errors }"
                    vid="current_password"
                    :rules="{ required: true, min: 6 }"
                  >
                    <div class="field">
                      <label>Current Password</label>
                      <CInput
                        type="password"
                        name="current_password"
                        v-model="profile.current_password"
                        placeholder="Current Password"
                        required
                        :addInputClasses="errors.length ? 'is-invalid' : ''"
                      />

                      <span class="text-danger mt-0 pt-0">{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>

                  <ValidationProvider
                    name="New Password"
                    v-slot="{ errors }"
                    vid="password"
                    :rules="{ required: true, min: 6 }"
                  >
                    <div class="field">
                      <label>New Password</label>
                      <CInput
                        type="password"
                        name="password"
                        v-model="profile.password"
                        placeholder="New Password"
                        required
                        :addInputClasses="errors.length ? 'is-invalid' : ''"
                      />
                      <span class="text-danger mt-0 pt-0">{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                  <ValidationProvider
                    name="Confirm Password"
                    v-slot="{ errors }"
                    vid="confirm_password"
                    :rules="{ required: true, min: 6 }"
                  >
                    <div class="field">
                      <label>Confirm Password</label>
                      <CInput
                        type="password"
                        name="password_confirmation"
                        v-model="profile.password_confirmation"
                        placeholder="Confirm Password"
                        required
                        :addInputClasses="errors.length ? 'is-invalid' : ''"
                      />
                      <span class="text-danger mt-0 pt-0">{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>

                  <button type="submit" class="btn btn-primary">
                    Update Password
                  </button>
                </form>
              </validation-observer>
            </li>
          </ul>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
export default {
  data() {
    return {
      image: null,
      profile: {
        current_password: "",
        password: "",
        password_confirmation: "",
      },
      serverErrors: [],
      showDismissibleAlert: false,
      breadcrumbs: [
        {text: "Home", path: "/dashboard", isActive: false},
        {text: "Profile", path: "", isActive: true}
      ],
    };
  },
  created() {
    this.$store.dispatch("Config/setBreadCrumb", this.breadcrumbs);
  },
  methods: {
    onPickFile() {
      this.$refs.fileInput.click();
    },
    onFilePicked(event) {
      const files = event.target.files;
      let filename = files[0].name;
      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        this.imageUrl = fileReader.result;
      });
      fileReader.readAsDataURL(files[0]);
      this.image = files[0];
      const formData = new FormData();
      formData.append("photo", this.image);
      this.axios
        .post("admin/users/upload-photo", formData)
        .then((res) => {
          this.$auth.watch.data.photo_url = res.data.data.photo_url;
          this.showToast = true;
          this.$toastr.s(
            "Photo successfully changed.",
            "Photo Changed!"
          );
        })
        .catch((err) => {
          if (err.response.data.message) {
            this.$refs.photoValidator.setErrors(err.response.data.errors.photo);
          }
        });
    },

    changePassword() {
      this.$store
        .dispatch("Profile/changePassword", this.profile)
        .then(() => {
          this.showToast = true;
          this.$toastr.s(
            "success",
            "Password successfully changed.",
            "Password Changed"
          );

          this.profile = {
            current_password: "",
            password: "",
            password_confirmation: "",
          };
          this.$refs.formValidator.reset();

        })
        .catch((err) => {
          this.serverErrors = [];
          if (err.response.data.message) {
            this.showDismissibleAlert = true;
            this.serverErrors.push(err.response.data.message);
            this.$refs.formValidator.setErrors(err.response.data.errors);
          }
        });
    },
  },

};
</script>

<style lang="scss" scoped>
.img {
  border-radius: 50%;
  width: 100px;
}
.error-text {
  display: inline-grid;
  grid-template-columns: repeat(auto-fit, 500px);
}
.card {
  border: none;
  padding: 30px 20px;
}
.avatar-container {
  max-width: 144px;
}
.customer-detail-list {
  .change-pass {
    font-weight: 600;
  }
  margin-top: 5px;
  list-style-type: none;
  padding: 0;
  li {
    width: 100%;
    float: left;
    p {
      float: left;
    }
    p {
      &:first-of-type {
        width: 20%;
        max-width: 60px;
        font-weight: 600;
      }
      &:last-of-type {
        width: 65%;
      }
    }
  }
}
</style>
